import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"absolute":"","color":"transparent","flat":"","height":"85"}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VToolbarTitle,{staticClass:"font-weight-light",domProps:{"textContent":_vm._s(_vm.title)}}),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }